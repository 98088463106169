import dynamic from 'next/dynamic'
import classNames from 'classnames'
import { Menu } from 'antd'

import { MenuOutlined } from '@ant-design/icons'

import styles from './Topbar.module.css'
import Link from '../Link'

const TopbarRight = dynamic(() => import("./TopbarRight"))

export enum TopbarTheme {
  Light = "light",
  Dark = "dark"
}

interface TopbarProps {
  theme?: TopbarTheme,
}

export default function Topbar({ theme = TopbarTheme.Dark }: TopbarProps) {

  const topbarClassName = classNames(
    styles.Topbar,
    {
      [styles['--theme-light']]: theme === TopbarTheme.Light,
      [styles['--theme-dark']]: theme === TopbarTheme.Dark,
    }
  )

  return (
    <>
      <div className={topbarClassName}>
        <div className={styles.Topbar_Logo_Container}>
          <Link href="/" className={styles.Topbar_Logo}>
            <img src={{
              [TopbarTheme.Dark]: "/logo-dark.svg",
              [TopbarTheme.Light]: "/logo-white.svg",
            }[theme]} />
          </Link>
        </div>
        <div className={styles.Topbar_Navbar_Container}>
          <nav className={styles.Topbar_Navbar} role="navigation">
            <Menu mode="horizontal" overflowedIndicator={<MenuOutlined />}>
              <Menu.Item key="/utbildningar">
                <Link href="/utbildningar">Våra utbildningar</Link>
              </Menu.Item>
              <Menu.Item key="/blogg">
                <Link href="/blogg">Blogg</Link>
              </Menu.Item>
              <Menu.Item key="/om-oss">
                <Link href="/om-oss">Om oss</Link>
              </Menu.Item>
              <Menu.Item key="/kontakt">
                <Link href="/kontakt">Kontakt</Link>
              </Menu.Item>
            </Menu>
          </nav>

        </div>
        <div className={styles.Topbar_Right}>
          <TopbarRight />
        </div>
      </div>
    </>
  )
}