import Head from "next/head";
import { PropsWithChildren } from "react";
interface PageMetaProps {
  title: string | string[],
  description?: string
}

export default function PageMeta({ title, description, children }: PropsWithChildren<PageMetaProps>) {
  const titleArray = [
    ...(Array.isArray(title) ? title : [title]),
    "Entreprenadakademin"
  ]

  return (
    <Head>
      <title>{titleArray.join(" | ")}</title>
      {description && <meta name="description" content={description} />}
      {children}
    </Head>
  )
}