import { useRef, useCallback, useMemo, ReactNode } from "react"
import qs from "qs"
import { partialOrderToQuery, PartialOrder } from "./PrefillOrderValuesHandler"
import { useOrder } from "../state/useOrder"
import { message } from "antd"
import { useRouter } from "next/dist/client/router"
import { getFullUrl } from "../utils/urls"

export default function CopyPrefillOrderValuesShareLinkHandler({ children }: { children: (onClick: () => void) => ReactNode }) {
  const [, order] = useOrder()
  const router = useRouter()

  const shareLink = useMemo<string>(() => {
    const query = partialOrderToQuery(order as PartialOrder)
    const nextQueryString = qs.stringify(query, {
      allowDots: true,
      encodeValuesOnly: true,
      encode: true,
      format: "RFC1738",
    })

    return getFullUrl(`${router.asPath}?${nextQueryString}`)
  }, [order, router])

  const handleCopyShareLink = useCallback(async () => {
    try {
      navigator.clipboard.writeText(shareLink)
      message.info("Dela-länk kopierades till urklipp")
    } catch (error) {
      message.error("Nånting blev fel.")
    }
  }, [shareLink])

  return (
    <>
      {children(handleCopyShareLink)}
    </>
  )
}