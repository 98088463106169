
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import { Row, Col } from "antd";
import {
  FacebookFilled,
  InstagramFilled,
  LinkedinFilled,
  ShareAltOutlined,
} from "@ant-design/icons";
import CopyPrefillOrderValuesShareLinkHandler from "../../containers/CopyPrefillOrderValuesShareLinkHandler";

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const DarkSection = makeShortcode("DarkSection");
const Button = makeShortcode("Button");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <DarkSection verticalSpacing="large" width="wider" topMargin="xlarge" mdxType="DarkSection">
  <Row gutter={[32, 32]} mdxType="Row">
    <Col xs={24} sm={24} md={8} mdxType="Col">
      <p><img src="/logo-white.svg" style={{
              width: 90,
              height: 70
            }} alt="Logotyp för Entreprenadakademin AB" /></p>
      <p>Entreprenadakademin är en utbildningsportal för webbutbildningar riktade till entreprenadbranschen. Genom att göra kunskapen mer tillgänglig utan avkall på kvalitén möjliggör vi att medarbetare får rätt kompetens och de intyg som krävs.</p>
    </Col>
    <Col xs={24} sm={12} md={8} mdxType="Col">
      <h3>Sidor</h3>
      <nav>
        <Row gutter={[16, 16]} mdxType="Row">
          <Col xs={12} mdxType="Col">
            <ul className="list --noDots">
              <li><a href="/utbildningar">Våra utbildningar</a></li>
              <li><a href="/blogg">Blogg</a></li>
            </ul>
            <ul className="list --noDots">
              <li><a href="/om-oss">Om oss</a></li>
              <li><a href="/kontakt">Kontakt</a></li>
            </ul>
          </Col>
          <Col xs={12} mdxType="Col">
            <ul className="list --noDots">
              <li><a href="/instruktioner">Instruktioner</a></li>
              <li><a href="/vanliga-fragor">Vanliga frågor</a></li>
              <li><a href="/lankar">Länkar</a></li>
              <li><a href="/villkor">Villkor</a></li>
            </ul>
          </Col>
        </Row>
      </nav>
    </Col>
    <Col xs={24} sm={12} md={8} mdxType="Col">
      <h3>Kontaktuppgifter</h3>
      <p>
        <b>Entreprenadakademin AB</b><br />
        Org.nr: 559078-7320<br />
        Boråsenliden 19<br />
        441 91 Alingsås
      </p>
      <p>
        073-077 99 15<br />
        jenny@entreprenadakademin.se
      </p>
      <p style={{
            fontSize: 32,
            letterSpacing: ".5em",
            marginBottom: ".5em"
          }}>
        <a href="https://www.facebook.com/entreprenadakademin" native external><FacebookFilled mdxType="FacebookFilled" /></a> <a href="https://www.instagram.com/entreprenadakademin.se/" native external><InstagramFilled mdxType="InstagramFilled" /></a> <a href="https://www.linkedin.com/company/entreprenadakademin" native external><LinkedinFilled mdxType="LinkedinFilled" /></a>
      </p>
      <p>
        <CopyPrefillOrderValuesShareLinkHandler mdxType="CopyPrefillOrderValuesShareLinkHandler">
          {onClick => <Button ghost onClick={onClick} icon={<ShareAltOutlined mdxType="ShareAltOutlined" />} mdxType="Button">Skapa dela-länk</Button>}
        </CopyPrefillOrderValuesShareLinkHandler>
      </p>
    </Col>
  </Row>
    </DarkSection>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;