import { PropsWithChildren } from "react";
import Footer from "../components/Footer/Footer";
import Topbar, { TopbarTheme } from "../components/Topbar/Topbar";
import style from "./DefaultLayout.module.css";

interface LayoutProps {
  topbarTheme?: TopbarTheme
}

export default function DefaultLayout({ topbarTheme, children }: PropsWithChildren<LayoutProps>) {
  return (
    <div className={style.DefaultLayout}>
      <Topbar theme={topbarTheme} />
      <main>
        {children}
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}
