import { useRouter } from "next/dist/client/router"
import { useEffect } from "react"
import { ParsedUrlQuery } from "querystring"
import { Company, InvoiceMethods, InvoiceOrEInvoiceSettings, CommonInvoiceSettings, useOrderApi, InvoiceSettings, useOrder } from "../state/useOrder"
import { message } from "antd"

export interface PartialOrder {
  company?: Company,
  invoiceMethod?: InvoiceMethods,
  invoiceSettings?: InvoiceOrEInvoiceSettings
}

enum QueryKeys {
  CompanyName = 'order.company.name',
  CompanyRegistrationNumber = 'order.company.registrationNumber',
  InvoiceMethod = 'order.invoiceMethod',
  InvoiceSettingsEmail = 'order.invoiceSettings.email',
  InvoiceSettingsReference = 'order.invoiceSettings.reference',
  InvoiceSettingsAddress = 'order.invoiceSettings.address',
  InvoiceSettingsPostalCode = 'order.invoiceSettings.postalCode',
  InvoiceSettingsCity = 'order.invoiceSettings.city',
}

interface IQueryValuesToPartialOrderMapper {
  [key: string]: (order: PartialOrder, value: string) => PartialOrder
}

const queryValuesToPartialOrderMap: IQueryValuesToPartialOrderMapper = {
  [QueryKeys.CompanyName]: (order, value) => ({ ...order, company: { registrationNumber: order.company?.registrationNumber || "", name: value } }),
  [QueryKeys.CompanyRegistrationNumber]: (order, value) => ({ ...order, company: { name: order.company?.name || "", registrationNumber: value } }),
  [QueryKeys.InvoiceMethod]: (order, value) => ({ ...order, invoiceMethod: value as InvoiceMethods }),
  [QueryKeys.InvoiceSettingsEmail]: (order, value) => ({ ...order, invoiceSettings: { ...(order.invoiceSettings as CommonInvoiceSettings), email: value } }),
  [QueryKeys.InvoiceSettingsReference]: (order, value) => ({ ...order, invoiceSettings: { ...(order.invoiceSettings as CommonInvoiceSettings), reference: value } }),
  [QueryKeys.InvoiceSettingsAddress]: (order, value) => ({ ...order, invoiceSettings: { ...(order.invoiceSettings as CommonInvoiceSettings), address: value } }),
  [QueryKeys.InvoiceSettingsPostalCode]: (order, value) => ({ ...order, invoiceSettings: { ...(order.invoiceSettings as CommonInvoiceSettings), postalCode: value } }),
  [QueryKeys.InvoiceSettingsCity]: (order, value) => ({ ...order, invoiceSettings: { ...(order.invoiceSettings as CommonInvoiceSettings), city: value } }),
}

function queryToPartialOrder(query: ParsedUrlQuery): PartialOrder {

  const partialOrder = Object.entries(query).reduce<PartialOrder>((partialOrderAccum, [queryKey, queryValue]) => {

    if (Object.values(QueryKeys).includes(queryKey as QueryKeys)) {
      const queryValuesToPartialOrderValuesFn = queryValuesToPartialOrderMap[queryKey]
      const queryValuesToPartialOrderValues = queryValuesToPartialOrderValuesFn(partialOrderAccum, queryValue as string)

      return {
        ...partialOrderAccum,
        ...queryValuesToPartialOrderValues
      }
    }

    return partialOrderAccum
  }, {})

  return partialOrder
}

interface IPartialOrderValuesToQueryValuesMap {
  [key: string]: (partialOrder: PartialOrder) => string | undefined
}

const partialOrderValuesToQueryValuesMap: IPartialOrderValuesToQueryValuesMap = {
  [QueryKeys.CompanyName]: (partialOrder) => partialOrder.company?.name,
  [QueryKeys.CompanyRegistrationNumber]: (partialOrder) => partialOrder.company?.registrationNumber,
  [QueryKeys.InvoiceMethod]: (partialOrder) => partialOrder.invoiceMethod,
  [QueryKeys.InvoiceSettingsEmail]: (partialOrder) => partialOrder.invoiceSettings?.email,
  [QueryKeys.InvoiceSettingsReference]: (partialOrder) => partialOrder.invoiceSettings?.reference,
  [QueryKeys.InvoiceSettingsAddress]: (partialOrder) => (partialOrder.invoiceSettings as InvoiceSettings)?.address,
  [QueryKeys.InvoiceSettingsPostalCode]: (partialOrder) => (partialOrder.invoiceSettings as InvoiceSettings)?.postalCode,
  [QueryKeys.InvoiceSettingsCity]: (partialOrder) => (partialOrder.invoiceSettings as InvoiceSettings)?.city,
}


interface PartialOrderQuery {
  [key: string]: string
}

export function partialOrderToQuery(partialOrder: PartialOrder): PartialOrderQuery {
  return Object.entries(partialOrderValuesToQueryValuesMap).reduce((queryAccum, [queryKey, queryKeyFn]) => {
    const queryKeyValue = queryKeyFn(partialOrder)
    if (queryKeyValue) {
      return {
        ...queryAccum,
        [queryKey]: queryKeyValue
      }
    }

    return queryAccum
  }, {})
}

interface PrefillOrderValuesHandlerProps {

}

export default function PrefillOrderValuesHandler({ }: PrefillOrderValuesHandlerProps) {
  const { query } = useRouter()
  const [] = useOrder()
  const { setOrder } = useOrderApi()

  useEffect(() => {
    const partialOrderFromQuery = queryToPartialOrder(query)

    const queryHasNewOrderValues = (
      !!partialOrderFromQuery.company ||
      !!partialOrderFromQuery.invoiceMethod ||
      !!partialOrderFromQuery.invoiceSettings
    )

    if (queryHasNewOrderValues) {
      setOrder((currentOrder) => ({ ...currentOrder, ...partialOrderFromQuery }), true)
      message.info("Förifyllda företags- och faktureringsuppgifter har sparats.", 6)
    }

  }, [query, setOrder])

  return null
}